<template>
    <div class="v-modal">
        <loading-spinner v-if="isFormLoaded" class="LoadingSpinner--overlay"/>

        <div class="modal-content">
            <div class="modal-header question-form-header">
                <h3 class="block-title">
                    <span v-if="question">Редактировать вопрос</span>
                    <span v-else>Добавить вопрос</span>
                </h3>

                <div class="block-options close-question-form-wrap">
                    <button
                        type="button"
                        class="btn-block-option close-question-form close-modal"
                        @click="$modal.hide('question-change-modal')"
                    >
                        ×
                    </button>
                </div>
            </div>
            <div class="modal-body question-form-body">
                <ValidationObserver
                    tag="form"
                    ref="observerQuestion"
                    class="form_question without_double_block"
                    @submit.prevent="onSubmit"
                >
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group form-group--category">
                                <label for="id-material" class="mb-20">Обязательно к изучению</label>

                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="form-radio">
                                            <label class="css-control mb-5">
                                                <input
                                                    type="radio"
                                                    id="id-material"
                                                    name="type"
                                                    class="css-control-input"
                                                    value="material"
                                                    v-model="category"
                                                >

                                                <span class="css-control-indicator"></span>
                                                Статья базы знаний
                                            </label>
                                        </div>
                                    </div>

                                    <div class="col">
                                        <div
                                            :class="[
                                            'form-material floating open',
                                            { 'is-invalid': category === 'material' && (!errors.selectMaterial.isValid || !this.errors.accessMaterial.isValid) },
                                            { 'is-disabled': category !== 'material' },
                                        ]"
                                        >
                                            <v-select2
                                                class="form-material"
                                                id="id_material"
                                                aria-selected="true"
                                                placeholder="Выберите материал"
                                                label="title"
                                                :clearable="false"
                                                :options="listMaterials"
                                                :reduce="title => title.id"
                                                v-model="form.material"
                                            >
                                                <span slot="no-options">Ничего не найдено</span>
                                            </v-select2>

                                            <div
                                                v-if="category === 'material' && (!errors.selectMaterial.isValid || !this.errors.accessMaterial.isValid)"
                                                id="id_material-error"
                                                class="invalid-feedback animated fadeInDown invalid--bottom"
                                            >
                                                {{ errors.selectMaterial.text }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 col-md-4">
                                        <div class="form-radio">
                                            <label class="css-control mb-5">
                                                <input
                                                    type="radio"
                                                    id="id-right"
                                                    name="type"
                                                    class="css-control-input"
                                                    value="document"
                                                    v-model="category"
                                                >

                                                <span class="css-control-indicator"></span>
                                                Документ
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div
                                            :class="[
                                                'form-material floating open',
                                                { 'is-invalid': category === 'document' && !errors.selectDocument.isValid },
                                                { 'is-disabled': category !== 'document' },
                                            ]"
                                        >
                                            <v-select2
                                                class="form-material"
                                                id="id_document"
                                                label="name"
                                                placeholder="Выберите документ"
                                                :clearable="false"
                                                :options="listDocuments"
                                                :reduce="name => name.id"
                                                v-model="form.document"
                                            >
                                                <span slot="no-options">Ничего не найдено</span>
                                            </v-select2>

                                            <div
                                                v-if="category === 'document' && !errors.selectDocument.isValid"
                                                id="id_document-error"
                                                class="invalid-feedback animated fadeInDown invalid--bottom"
                                            >
                                                {{ errors.selectMaterial.text }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-12 ">
                            <label for="id_knowledge_block">
                                Блок знаний
                            </label>

                            <vue-bootstrap-typeahead
                                id="id_knowledge_block"
                                name="knowledge_block"
                                ref="knowledgeBlockTypeahead"
                                :data="knowledgeBlockChange"
                                :serializer="item => item.name"
                                :disabledValues="true"
                                v-model="form.knowledge_block"
                            />
                        </div>

                        <div class="form-group col-12" :class="{'is-invalid': errors.text}">
                            <div>
                                <label for="id_text">Введите формулировку вопроса *</label>
                                <textarea
                                    name="text"
                                    rows="3"
                                    maxlength="1024"
                                    id="id_text"
                                    cols="40"
                                    class="form-control"
                                    v-model="form.text"
                                ></textarea>

                                <div
                                    v-if="errors.text"
                                    id="id_text-error"
                                    class="invalid-feedback animated fadeInDown invalid--bottom"
                                >
                                    Обязательное поле.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 form-group">
                            <div class="form-material floating open" :class="{'is-invalid': errors.selectType}">
                                <label for="id_type_answer">
                                    Тип ответа *
                                </label>

                                <v-select2
                                    label="label"
                                    ref="selectTypeAnswer"
                                    id="id_type_answer"
                                    class="form-material"
                                    :clearable="false"
                                    :searchable="false"
                                    :options="listTypeAnswer"
                                    :reduce="label => label.value"
                                    v-model="form.type_answer"
                                >
                                    <span slot="no-options">Ничего не найдено</span>
                                </v-select2>

                                <div
                                    v-if="errors.selectType"
                                    id="id_type_answer-error"
                                    class="invalid-feedback animated fadeInDown invalid--bottom"
                                >
                                    Обязательное поле.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 form-group display_flex align-items-end">
                            <div class="question-logo">
                                <div class="logo__preview">
                                    <div
                                        class="logo__img-wrap"
                                        v-if="form.file"
                                        @click="showPhotoModal"
                                    >
                                        <img
                                            :src="preview"
                                            alt=""
                                            class="logo__img"
                                        >
                                        <i class="logo__img-icon"/>
                                    </div>
                                    <span v-else class="logo__letter"></span>
                                </div>
                                <div class="logo__wrap">
                                    <label v-if="!form.file" for="id_image" class="logo__load v-link">
                                        Загрузить изображение
                                    </label>
                                    <span @click="handleFileDelete" v-else class="logo__load v-link">
                                        Удалить изображение
                                    </span>
                                    <span class="logo__meta">
                                        {{!form.file ? 'jpeg, jpg, png, svg не более 2 мб' : 'Нажмите на изображение, чтобы просмотреть его'}}
                                    </span>
                                </div>
                                <div class="hidden">
                                    <input class="hidden" type="file" name="image" accept="image/*" id="id_image" ref="questionFile"
                                           @change="handleFileUpload">
                                    <input class="hidden" v-model="form.file">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive push">
                        <div class="form-group">
                            <div v-if="form.type_answer === 2" class="form-material floating open answer-choices">
                                <label class="">Правильный ответ *</label>
                                <textarea
                                    v-for="(item, index) in form.listAnswer" :key="'item_' + index"
                                    name="text" rows="3"
                                    maxlength="1024"
                                    class="form-control"
                                    cols="40"
                                    v-model="item.text"
                                    :id="'id_answer_set-' + index + '-text'"
                                    :name="'answer_set-' + index + '-text'"
                                >
                                </textarea>

                                <div
                                    v-if="!errors.answer.isValid"
                                    class="invalid-feedback animated fadeInDown invalid--bottom"
                                >{{ errors.answer.error }}</div>
                            </div>

                            <div v-else class="form-material floating open answer-choices">
                                <label class="">Укажите варианты ответа *</label>

                                <div class="question__list">
                                    <div
                                        v-for="(item, index) in form.listAnswer"
                                        :key="'item_' + index"
                                        class="question__item"
                                    >
                                        <input
                                            type="hidden"
                                            :name="'answer_set-' + index + '-id'"
                                            :id="'id_answer_set-' + index + '-id'"
                                        >

                                        <v-textArea
                                            class="question__field"
                                            :id="'id_answer_set-' + index + '-text'"
                                            :name="'answer_set-' + index + '-text'"
                                            :max-length="1024"
                                            :value="item.text"
                                            @input="(value) => onQuestionItem(value, item)"
                                        />

                                        <div class="question__triggers">
                                            <label
                                                title="Правильный ответ"
                                                :class="[
                                                    'question-trigger',
                                                    'question-trigger--success',
                                                    { 'question-trigger--active': item.correct },
                                                    { 'question-trigger--filled': item.text !== '' }
                                                ]"
                                            >
                                                <span class="question-trigger__status"></span>
                                                <span class="question-trigger__checkbox">
                                                    <input
                                                        :name="'id_answer_set-' + index + '-correct'"
                                                        :id="'id_answer_set-' + index + '-correct'"
                                                        v-model="item.correct"
                                                        type="checkbox"
                                                        class="question-trigger__input"
                                                    >
                                                    <span class="question-trigger__replace"></span>
                                                    <span class="question-trigger__text">
                                                        Правильный ответ
                                                    </span>
                                                </span>
                                            </label>

                                            <button
                                                v-if="index > 1"
                                                type="button"
                                                title="Удалить"
                                                class="question-trigger question-trigger--delete"
                                                @click="removeAnswer(index)"
                                            ></button>
                                        </div>
                                    </div>
                                </div>

                                <div class="add_one_wrapper">
                                    <div
                                        v-if="!this.errors.answer.isValid"
                                        class="showed-error-js invalid-feedback animated fadeInDown showed invalid--top"
                                    >
                                        {{ this.errors.answer.error }}
                                    </div>

                                    <button
                                        type="button"
                                        class="add_one_question btn btn-alt-secondary btn-block"
                                        @click="addAnswer"
                                    >
                                        + Добавить вариант ответа
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div v-show="category !== 'document'" class="question-permission">
                            <span v-show="!infoPermissionText">
                                Доступы не указаны
                            </span>
                            <span v-show="infoPermissionText">
                                <span class="material__access-txt">{{ infoPermissionText }}</span>
                            </span>
                            <a href="javascript:void(0)" class="question-permission__link"
                               @click="changePermission">
                                <svg width="15" height="15" viewBox="0 0 15 15"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.724 1.274a4.35 4.35 0 00-6.966 5.022L.006 13.05l-.004 1.947L3.778 15v-1.836h1.836v-1.837h1.837V9.491L8.7 8.24a4.351 4.351 0 005.023-6.966zm-1.383 3.243a1.315 1.315 0 11-1.86-1.86 1.315 1.315 0 011.86 1.86z"></path>
                                </svg>
                                Настроить доступ
                            </a>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-rounded btn-primary-dark">
                            <span v-if="question">Сохранить</span>
                            <span v-else>Добавить</span>
                        </button>
                        <button v-if="!question" type="button" class="btn btn-rounded btn-alt-secondary" @click="resetForm">
                            Сбросить
                        </button>
                        <button v-else type="button" class="btn btn-rounded btn-alt-secondary" @click="$modal.hide('question-change-modal')">
                             Отмена
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
        <vue-progress-bar/>
    </div>
</template>

<script>
    import session from '@/api/session';
    import LoadingSpinner from '@/components/LoadingSpinner';
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    import validation from "assets/js/validate";

    export default {
        name: 'QuestionChangeModal',
        components: {
            VueBootstrapTypeahead,
            LoadingSpinner,
            vTextArea: () => import('@/components/TextArea')
        },
        props: {
            categoryType: {
                type: String,
                default: 'material'
            },

            categoryId: {
                required: false,
                default: null
            },

            categoryTitle: {
                type: String,
                default() {
                    if (this.categoryType === 'document') {
                        return 'Этот документ';
                    }

                    if (this.categoryType === 'material') {
                        return 'Этот материал'
                    }
                }
            },

            permissionData: {
                type: Object,
                required: false
            },

            question: {
                type: Object,
                required: false
            },

            onSave: {
                type: Function
            },
        },
        data() {
            return {
                category: JSON.parse(JSON.stringify(this.categoryType)),
                infoPermissionText: 'Доступен всей компании',
                form: {
                    right_answer: '',
                    permissionData: {structure: {}, users: [], access_all: true, note: {}},
                    material: '',
                    document: '',
                    type_answer: 0,
                    text: '',
                    knowledge_block: '',
                    listAnswer: [
                        {
                            'text': '',
                            'correct': false
                        },
                        {
                            'text': '',
                            'correct': false
                        }
                    ],
                    file: ''
                },
                knowledgeBlockStart: [],
                knowledgeBlockChange: [],
                listTypeAnswer: [
                    {
                        value: 0,
                        label: 'Один правильный ответ'
                    },
                    {
                        value: 1,
                        label: 'Несколько правильных ответов'
                    },
                    {
                        value: 2,
                        label: 'Открытый вопрос'
                    }
                ],
                errors: {
                    isValid: true,
                    selectMaterial: {
                        isValid: true,
                        error: ''
                    },
                    selectDocument: {
                        isValid: true,
                        error: ''
                    },
                    accessMaterial: {
                        isValid: true
                    },
                    selectType: false,
                    text: false,
                    answer: {
                        isValid: true,
                        error: ''
                    },
                },
                isValidSelect2: true,
                clickSubmit: false,
                listMaterials: [],
                listDocuments: [],
                preview: null,
                validation,
                isFormLoaded: false,
                test: 94,
            }
        },
        async created() {
            if (this.permissionData) {
                this.form.permissionData = this.permissionData;
                await this.setInfoText(this.permissionData);
            }

            if (this.categoryId) {
                const id = Number(this.categoryId);

                if (this.categoryType === 'document') {
                    this.form.document = id
                }

                if (this.categoryType === 'material') {
                    this.form.material = id
                }
            }

            session
                .get('/api/v1/document/all_list/')
                .then(response => {
                    this.listDocuments = response.data

                    const findResult = this.listDocuments.find((doc) => doc.id === this.categoryId);

                    if (!findResult) {
                        this.listDocuments.push({
                            name: this.categoryTitle,
                            id: this.form.document
                        })
                    }
                })
                .catch(() => {});

            session
                .get('/api/v1/material/all_list/')
                .then(response => this.listMaterials = response.data)
                .catch(() => {});

            session
                .get('/api/v1/knowledge-block/')
                .then(response => this.knowledgeBlockStart = response.data)
                .catch(() => {});

            if (this.question) {
                session
                    .get(`/api/v1/question/${this.question.id}/`)
                    .then(response => {
                        this.form = response.data;

                        if (response.data.file) {
                            this.preview = response.data.file;
                        }

                        this.$refs.knowledgeBlockTypeahead.inputValue = this.form.knowledge_block;
                        this.form.type_answer = Number(this.form.type_answer);
                        this.infoPermissionText = response.data.permission_text;
                    })
                    .catch(() => {});
            }
        },
        watch: {
            form: {
                handler: function (val, oldVal) {
                    if (this.clickSubmit) {
                        this.validateAnswer();
                        this.validateSelect2();
                    }
                },
                deep: true
            },

            'form.knowledge_block': {
                handler: function (newVal, oldVal) {
                    this.knowledgeBlockChange = (newVal !== '') ? this.knowledgeBlockStart.filter((variable) => variable.name.toLowerCase().indexOf(newVal.toLowerCase()) !== -1) : [];
                },
                deep: true
            }
        },
        methods: {
            resetForm(){
                this.form = {
                    right_answer: '',
                    permissionData: this.permissionData ? this.permissionData : {structure: {}, users: [], access_all: true, note: {}},
                    material: '',
                    type_answer: '',
                    text: '',
                    knowledge_block: '',
                    listAnswer: [
                        {
                            'text': '',
                            'correct': false
                        },
                        {
                            'text': '',
                            'correct': false
                        }
                    ],
                    file: ''
                };
                this.$refs.knowledgeBlockTypeahead.inputValue = '';
                this.setInfoText(this.permissionData);
            },
            async setInfoText(data) {
                if (data) {
                    try {
                        const request = session.post('/api/v1/permission/info_text/', data);
                        let response = await request;
                        this.infoPermissionText = response.data['text'];
                    } catch (err) {
                        console.error(err)
                    }
                }
            },
            handleFileDelete() {
                this.form.file = null;
                this.preview = null;
            },
            async handleFileUpload(e) {
                let reader = new FileReader();
                let file = e.target.files[0];
                reader.addEventListener('load', function() {
                    let isValid = this.validation.file(file, ['jpg', 'jpeg', 'png', 'svg', 'JPG', 'JPEG', 'PNG', 'SVG'], 2000);
                    this.$refs.questionFile.value = null;
                    if (isValid) {
                        this.$Progress.start();
                        // this.form.file = file;
                        this.form.file = file;
                        this.preview = URL.createObjectURL(file);
                        this.isNewImage = true;
                        this.$Progress.finish();
                    }
                }.bind(this), false);
                if (file) {
                    reader.readAsDataURL(file);
                }
            },
            showPhotoModal() {
                const PhotoModal = () => import(`../../accounts/profile/PhotoModal`);
                this.$modal.show(PhotoModal, {
                    image: this.preview,
                    text: this.form.file.name
                }, {
                    name: 'photo-modal',
                    classes: 'photo-modal'
                })
            },
            async onSubmit() {
                let isValidQuestion = this.$refs.observerQuestion.validate();
                this.clickSubmit = true;
                await this.validateAnswer();
                await this.validateSelect2();

                if (this.errors.isValid && this.errors.answer.isValid && this.errors.accessMaterial.isValid) {
                    try {
                        let data = this.form;
                        let config = {};

                        this.isFormLoaded = true;

                        if (this.isNewImage) {
                            config['headers'] = {
                                'Content-Type': 'multipart/form-data'
                            };
                            // Добавляем данные из объекта в formData для отправки на сервер
                            let formData = new FormData();
                            for (let [key, value] of Object.entries(data)) {
                                //Если значение - массив, то переводим его значения в формат images[0]: file, images[1]: file...
                                if (key === 'permissionData') {
                                    formData.append('permissionData', JSON.stringify(value));
                                } else if (key === 'file') {
                                    formData.append('file', value);
                                } else if (key === 'listAnswer') {
                                    formData.append('listAnswer', JSON.stringify(value));
                                } else if (typeof (value) === 'object') {
                                    for (let item of value) {
                                        formData.append(`${key}[]`, JSON.stringify(item));
                                    }
                                } else {
                                    formData.append(key, value);
                                }
                            }
                            data = formData;
                        } else {
                            delete data.file;
                        }

                        if (this.question) {
                            let request = session.put(`/api/v1/question/${this.question.id}/`, data);
                            let response = await request;
                            this.$modal.hide('question-change-modal', {item: this.question, new_item: response.data});
                            this.$swal({
                                title: 'Отлично!',
                                text: 'Вопрос изменен',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400,
                            });
                        } else {
                            let request = session.post('/api/v1/question/', data);
                            let response = await request;
                            this.$modal.hide('question-change-modal', {item: null, new_item: response.data});
                            this.$swal({
                                title: 'Отлично!',
                                text: 'Вопрос добавлен',
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 1400,
                            });
                        }
                        this.onSave();
                        this.isFormLoaded = false;
                    } catch (err) {
                        console.error(err);
                        this.isFormLoaded = false;
                        if (err.response) {
                            this.$refs.observerQuestion.setErrors(err.response.data);
                        }
                    }
                }
            },
            async validateAnswer() {
                let text_repeat = 0;
                let count_checkbox = 0;
                this.errors.answer.error = '';
                this.errors.answer.isValid = true;
                this.form.listAnswer.map((element) => {
                    if (element.text === '') {
                        this.errors.answer.error = 'Заполните все варианты ответов.';
                    }
                    if (element.correct) {
                        count_checkbox += 1;
                    }
                    this.form.listAnswer.map((item) => {
                        if (element.text && element.text === item.text && element.id !== item.id) {
                            text_repeat += 1
                        }
                    });
                });
                if (this.form.type_answer === this.listTypeAnswer[0].value && count_checkbox !== 1) {
                    this.errors.answer.error = 'Выберите правильный ответ.';
                }
                if (this.form.type_answer === this.listTypeAnswer[1].value && count_checkbox < 2) {
                    this.errors.answer.error = 'Выберите несколько правильных ответов.';
                }
                if (text_repeat > 0) {
                    this.errors.answer.error = 'Введите разные ответы.';
                }
                if (this.errors.answer.error) {
                    this.errors.answer.isValid = false;
                }
            },
            async validateSelect2() {
                this.errors.selectType = false;

                this.errors.selectMaterial.text = '';
                this.errors.selectMaterial.isValid = true;

                this.errors.selectDocument.text = '';
                this.errors.selectDocument.isValid = true;

                this.errors.text = false;
                this.errors.isValid = true;

                if (this.form.text.length === 0) {
                    this.errors.text = true;
                    this.errors.isValid = false;
                }

                if (this.category === 'material') {
                    if (this.form.material.length === 0) {
                        this.errors.selectMaterial.isValid = false;
                        this.errors.selectMaterial.text = 'Обязательное поле.';
                        this.errors.isValid = false;
                    } else {
                        await this.accessMaterial();
                    }
                } else {
                    if (this.form.document.length === 0) {
                        this.errors.selectDocument.isValid = false;
                        this.errors.selectDocument.text = 'Обязательное поле.';
                        this.errors.isValid = false;
                    }
                }

                if (this.form.type_answer.length === 0) {
                    this.errors.selectType = true;
                    this.errors.isValid = false;
                }
            },
            addAnswer() {
                this.form.listAnswer.push({
                    'text': '',
                    'correct': false
                })
            },
            removeAnswer(index) {
                this.form.listAnswer.splice(index, 1);
            },
            changePermission() {
                if (this.question) {
                    const ModalPermissionUpdate = () => import(`../../permission/ModalPermissionUpdate`);
                    this.$modal.show(ModalPermissionUpdate, {
                        title: 'Настройка доступа к вопросу',
                        text: 'Выберите отделы, должности или сотрудников, которым необходим доступ к данному вопросу',
                        permissionType: 'question',
                        permissionObjectID: this.form.id,
                        showUsersTab: false
                    }, {
                        name: 'ModalPermissionUpdate',
                        adaptive: true,
                        maxWidth: 900,
                        width: '100%',
                        height: 'auto'
                    }, {
                        'before-close': this.updateTextPermission
                    })
                } else {
                    const ModalPermissionCreate = () => import(`../../permission/ModalPermissionCreate`);
                    this.$modal.show(ModalPermissionCreate, {
                        title: 'Настройка доступа к вопросу',
                        text: 'Выберите отделы, должности или сотрудников, которым необходим доступ к данному вопросу',
                        permissionData: this.form.permissionData,
                        showUsersTab: false
                    }, {
                        name: 'ModalPermissionCreate',
                        adaptive: true,
                        maxWidth: 900,
                        width: '100%',
                        height: 'auto'
                    }, {
                        'before-close': this.updateTextPermission
                    })
                }
            },
            async updateTextPermission(data) {
                if (data.params) {
                    data = data.params.data;
                    this.form.permissionData = data;
                    this.infoPermissionText = data.info_text;
                    await this.accessMaterial()
                }
            },
            async accessMaterial() {
                if (this.form.material) {
                    try {
                        let request = session.post(
                            `/api/v1/testing_access/is_access_material/?material_id=${this.form.material}`,
                            this.form.permissionData
                        );
                        let response = await request;

                        this.errors.accessMaterial.isValid = true;
                        this.errors.selectMaterial.text = '';

                        if (response.data.is_access === false) {
                            this.errors.accessMaterial.isValid = false;
                            this.errors.selectMaterial.text = 'Доступы материала и вопроса не пересекаются.';
                        }
                    } catch (err) {
                        console.error(err);
                        if (err.response) {
                            this.$refs.observerQuestion.setErrors(err.response.data);
                        }
                    }
                }
            },
            onQuestionItem(value, item) {
                if (value === '') {
                    item.correct = false;
                }

                item.text = value;
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/v-style";

    .question-logo {
        display: flex;
        align-items: center;
        .logo {
            &__preview {
                display: flex;
                margin: 0;
                cursor: pointer;
                height: 54px;
                width: 54px;
                max-height: 54px;
                max-width: 54px;
            }
            &__wrap {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 15px;
            }
            &__load {
                cursor: pointer;
                color: #2173D3;
                font-weight: normal;
                margin: 0;
            }
            &__img {
                height: 54px;
                width: 54px;
                border-radius: 4px;
                filter: blur(3px) brightness(50%);
                &-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    &:hover {
                        i {
                            transform: scale(1.2);
                        }
                    }
                }
                &-icon {
                    transition: transform .25s ease;
                    @include icon($loup-icon, 25px);
                    position: absolute;
                }
            }
            &__letter {
                width: 100%;
                height: 100%;
                background: #5B6071;
                color: #A7AFCB;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.75rem;
                border-radius: 5px;
            }
            &__meta {
                color: #999;
                font-size: 0.875rem;
                line-height: 0.875rem;
            }
        }
    }
    .show {
        display: block;
    }

    .display_flex {
        display: flex !important;
    }

    .is_invalid {
        border-color: #ef5350 !important;
    }

    .invalid-feedback {
        display: block;
        position: absolute;
        &.invalid {
            &--top {
                top: -25px;
            }

            &--bottom {
                bottom: -20px;
            }
        }
    }

    .is-invalid .v-select .vs__dropdown-toggle {
        border-color: #ef5350 !important;
    }

    .add_one_wrapper {
        position: relative;
    }

    .question-form-body {
        .answer-choices {
            textarea + textarea {
                margin-top: 8px;
            }
        }
    }

    .question-form-body {
        .form {
            &-group {
                &--category {
                    padding: 20px;
                    border-radius: 4px;
                    border: 1px solid #e6ebf3;
                }
            }
        }

        .is-disabled {
            opacity: .5;
            pointer-events: none;
        }
    }

    .question {
        $pn: &;

        &__list {
            gap: 8px;
            display: flex;
            flex-direction: column;
            margin: 15px 0;
        }

        &__item {
            position: relative;

            @media (hover: hover) {
                &:hover {
                    #{$pn} {
                        &__triggers {
                            opacity: 1;
                        }

                        &-trigger {
                            opacity: 1;

                            &--filled {
                                #{$pn}-trigger {
                                    &__checkbox {
                                        display: flex;
                                    }

                                    &__status {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &__field {
            .textarea__field {
                color: #575757;
                font-size: 1rem;
                font-weight: normal;
                line-height: 16px;
                padding: 8px 14px 40px;
                border-radius: 0.25rem;
                border-color: #d4dae3;
            }
        }

        &__triggers {
            right: 20px;
            bottom: 30px;
            position: absolute;

            gap: 10px;
            display: flex;
            align-items: center;
            padding: 6px;
            background-color: #fff;
            border-radius: 100px;
            box-shadow: 0 0 5px #ddd;

            @media (hover: hover) {
                opacity: .9;
            }
        }

        &-trigger {
            $pn: &;
            $colorRed: #fc4c42;
            $colorSuccess: #69ba10;

            cursor: pointer;
            position: relative !important;
            border: 0 !important;;
            padding: 0 !important;;
            margin: 0 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;

            &--delete {
                width: 18px;
                height: 18px;

                &:before,
                &:after {
                    content: " ";
                    top: 0;
                    left: 8px;
                    position: absolute;
                    height: 18px;
                    width: 1px;
                    background-color: #ccc;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }

                &:hover {
                    &:before,
                    &:after {
                        background-color: $colorRed;
                    }
                }
            }

            &__status {
                display: none;

                width: 18px;
                height: 18px;

                &:after {
                    content: '';
                    width: 9px;
                    height: 14px;
                    display: inline-block;
                    border-color: #ccc;
                    border-style: solid;
                    border-width: 0 1px 1px 0;
                    transform: rotate(45deg);
                }

                #{$pn}--active & {
                    &:after {
                        border-color: $colorSuccess;
                    }
                }

                @media (hover: hover) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &__checkbox {
                gap: 8px;
                display: flex;
                align-items: center;

                @media (hover: hover) {
                    display: none;
                }
            }

            &__input {
                width: 0.5px;
                height: 0.5px;
                padding: 0;
                position: absolute;
                &:checked {
                    & + #{$pn} {
                        &__replace {
                            background-color: $colorSuccess;
                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            &__replace {
                position: relative;
                width: 16px;
                height: 16px;
                display: inline-block;
                border-radius: 50%;
                border: 1px solid $colorSuccess;

                &:after {
                    content: "";
                    top: 1px;
                    left: 4px;
                    position: absolute;
                    width: 6px;
                    height: 9px;
                    display: block;
                    border-color: #fff;
                    border-style: solid;
                    border-width: 0 1px 1px 0;
                    transform: rotate(45deg);
                }
            }

            &__text {
                color: $colorSuccess;
                font-size: 14px;
                font-weight: normal;
            }
        }
    }
</style>
